<template>
  <Transition name="down">
    <div class="message" :style="style[type]" v-show="visible">
      <img class="iconfont" :src="[style[type].icon]" />
      <span class="text">{{ text }}</span>
    </div>
  </Transition>
</template>
<script>
import { onMounted, ref } from 'vue'
export default {
  name: 'message',
  props: {
    type: {
      type: String,
      default: 'warn'
    },
    text: {
      type: String,
      default: ''
    },
    bottom: {
      type: String,
      default: '160px'
    }
  },
  setup () {
    // 定义一个对象，包含三种情况的样式，对象key就是类型字符串
    const style = {
      warn: {
        icon: require('@/assets/icons/warning-toast.svg'),
        color: 'rgba(255, 255, 255, 0.9)',
        backgroundColor: 'rgb(42, 49, 72)',
        borderColor: '#2A3148'
      },
      error: {
        icon: require('@/assets/icons/error-toast.svg'),
        color: 'rgba(255, 255, 255, 0.9)',
        backgroundColor: 'rgb(42, 49, 72)',
        borderColor: '#2A3148'
      },
      success: {
        icon: require('@/assets/icons/success-toast.svg'),
        color: 'rgba(255, 255, 255, 0.9)',
        backgroundColor: 'rgb(42, 49, 72)',
        borderColor: '#2A3148'
      }
    }
    // 控制元素显示隐藏
    const visible = ref(false)
    onMounted(() => {
      visible.value = true
    })
    return { style, visible }
  }
}
</script>
<style scoped lang="scss">
/* down 延时动画 */
.message {
  position: fixed;
  width: 350px;
  margin-left: -175px;
  // top: 60px;
  bottom: 80px;
  left: 50%;
  z-index: 999999999;
  display: flex;
  padding: 15px 35px;
  align-items: flex-start;
  // height: 40px;
  border-radius: 4px;
  animation: down 0.3s ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.3s;
  img {
    width: 20px;
    margin-right: 8px;
  }
}

.message .text {
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  line-height: 1.5;
}
@keyframes down {
  0% {
    /* transform: translateY(-100%); */
  }
  100% {
    /* transform: translateY(0); */
  }
}
</style>
